import React, { useEffect, useRef, useLayoutEffect, useState } from "react"
import styled from "styled-components"
import Route from "../images/route.inline.svg"

const Shell = styled.div`
  height: 150px;
  width: 100%;
  position: relative;

  overflow: hidden;

  img {
    max-width: none;
    width: 1820px;
    height: 2363px;
    position: absolute;
  }
`

const RouteWithStyle = styled(Route)`
  position: absolute;
  width: 1820px;
  height: 2363px;

  path {
    fill: none;
    stroke: var(--primary-color);
    stroke-width: 4;
    opacity: 0; /* Do not show any path on load */
  }
`

const Mover = styled.div.attrs(({ x, y, angle }) => ({
  style: {
    transform: `translate3d(-${x}px, -${y - 50}px ,0) rotate(${angle +
      Math.PI / 2}rad)`,
    transformOrigin: `${x}px ${y}px`,
  },
}))`
  position: absolute;
  top: 0;
  left: 50%;
  width: 100%;
  height: 100%;
`

const Map = ({ path }) => {
  const svgRef = useRef(null)
  const isAnimationRunning = useRef(false)
  const [index, setIndex] = useState(undefined)
  const rotation = useRef(undefined)
  const [{ x, y, angle }, setPoint] = useState({ x: 0, y: 0, angle: 0 })

  useEffect(() => {
    if (index === undefined) {
      setIndex(-1)
    } else if (!isAnimationRunning.current) {
      isAnimationRunning.current = true
      setIndex(index + 1)
    }
  }, [path])

  useLayoutEffect(() => {
    const container = svgRef.current
    const paths = container.getElementsByTagName("path")
    for (const path of paths) {
    }
  }, [])

  useLayoutEffect(() => {
    const diff = 1000
    const total = 3 * 1000
    if (index === undefined) {
      const container = svgRef.current
      const paths = container.getElementsByTagName("path")
      if (paths.length > 0) {
        const route = paths[0]
        const point = route.getPointAtLength(0)
        const pathLength = route.getTotalLength()

        const nextPointTime = diff

        const nextPoint = route.getPointAtLength(
          (nextPointTime / (pathLength * 20)) * pathLength
        )

        const targetAngle = Math.atan2(
          -(nextPoint.x - point.x),
          -(nextPoint.y - point.y)
        )

        setPoint({ x: point.x, y: point.y, angle: targetAngle })
      }

      return
    }

    const container = svgRef.current
    const paths = container.getElementsByTagName("path")

    let route

    if (index < paths.length) {
      route = paths[index]
    }

    if (route) {
      const pathLength = route.getTotalLength()
      const total = pathLength * 20

      const maxChange = 0.005
      let startTime

      function nextFrame(time) {
        const currentTime = time - startTime
        const progress = currentTime / total

        const point = route.getPointAtLength(progress * pathLength)

        let targetAngle = rotation.current

        route.style["stroke-dasharray"] = pathLength
        route.style["stroke-dashoffset"] = pathLength + -progress * pathLength
        route.style["opacity"] = 0.6

        let nextPointTime = total
        if (currentTime + diff < total) {
          nextPointTime = currentTime + diff
        }

        const nextPoint = route.getPointAtLength(
          (nextPointTime / total) * pathLength
        )

        targetAngle = Math.atan2(
          -(nextPoint.x - point.x),
          -(nextPoint.y - point.y)
        )

        if (rotation.current) {
          const diffAngle = Math.atan2(
            Math.sin(targetAngle - rotation.current),
            Math.cos(targetAngle - rotation.current)
          )

          if (diffAngle > 0 && diffAngle > maxChange) {
            targetAngle = rotation.current + maxChange
          } else if (diffAngle < 0 && diffAngle < maxChange * -1) {
            targetAngle = rotation.current - maxChange
          }
        }

        rotation.current = targetAngle

        setPoint({ x: point.x, y: point.y, angle: rotation.current })

        if (currentTime < total) {
          window.requestAnimationFrame(nextFrame)
        } else {
          isAnimationRunning.current = false
        }
      }

      window.requestAnimationFrame(time => {
        startTime = time
        window.requestAnimationFrame(nextFrame)
      })
    }
  }, [index])

  return (
    <Shell>
      <Mover x={x} y={y} angle={angle} ref={svgRef}>
        <img src="/map.png" />
        <RouteWithStyle />
      </Mover>
    </Shell>
  )
}

export default Map
