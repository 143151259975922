import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Logo from "../images/logo.inline.svg"
import "./header.css"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"

const HeaderShell = styled.header`
  --color: white;
  background-color: var(--primary-color);
`

const HeaderContainer = styled.div`
  margin: 0 auto;
  max-width: 960px;
  padding: 0.5rem 1.0875rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`

const Navigation = styled.ul`
  display: flex;
  margin: 0;
  padding: 0;
  align-self: flex-end;
  justify-content: space-between;
`

const NavigationItem = styled.li`
  --side-margin: 0.6rem;
  list-style: none;
  margin-bottom: 0.1rem;
  margin-left: var(--side-margin);

  &:first-child {
    margin-left: 0;
  }
`

const NavigationLink = styled(Link)`
  color: var(--color);
  transition: all 0.3s;

  &:hover {
    color: var(--white-active);
  }
`

const Header = ({ siteTitle, navigation }) => {
  return (
    <HeaderShell>
      <HeaderContainer>
        <Link to="/" className="logoLink">
          <Logo className="logo" />
        </Link>

        <Navigation>
          {navigation.map(child => {
            return (
              <NavigationItem key={child.url}>
                <NavigationLink to={child.url}>{child.title}</NavigationLink>
              </NavigationItem>
            )
          })}
        </Navigation>
      </HeaderContainer>
    </HeaderShell>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
